export const combinations: any = [
  { value: "ANP", subject: "ANP" },
  { value: "BCG", subject: "BCG" },
  { value: "CSC", subject: "CSC" },
  { value: "CST", subject: "CST" },
  { value: "EFK", subject: "EFK" },
  { value: "EKK", subject: "EKK" },
  { value: "ELC", subject: "ELC" },
  { value: "HEL", subject: "HEL" },
  { value: "HEG", subject: "HEG" },
  { value: "HGL", subject: "HGL" },
  { value: "LEG", subject: "LEG" },
  { value: "MCB", subject: "MCB" },
  { value: "MCE", subject: "MCE" },
  { value: "MEG", subject: "MEG" },
  { value: "MPC", subject: "MPC" },
  { value: "MPG", subject: "MPG" },
  { value: "PCM", subject: "PCM" },
  { value: "PCB", subject: "PCB" },

];



export const scientificSubject: any = [
  'Mathematics',
  'Physics',
  'Geography',
  'Chemistry',
  'Biology',
  'Economics',
  'Computer Science',
  'History',
  'Literature in English',
  'Religious Education',
  'French',
  'Kinyarwanda',
  'Kiswahili'
];