export { getQuestions, registerAutoQuestions } from "./pandaquestionsauto";
export {
  getQuestionsAlgoV2,
  registerAutoQuestionsAlgoV2,
} from "./pandaautotestalgov2";

export { submitTestAIUsersAnswers } from "./Pandatestaiusersanswers";

export { submitTestAlgoV2UsersAnswers } from "./Pandaautotestalgov2usersanswers";

export {
  getTeacherDragAndDropQuestions,
  registerTeacherDragAndDropQuestions,
} from "./Pandadraganddrop202003";

export {
  getTeacherFillinQuestions,
  registerTeacherFillinQuestions,
} from "./Pandafillin202003";

export {
  getTeacherOpenEndedQuestions,
  registerTeacherOpenEndedQuestions,
} from "./Pandaopenended202003";

export {
  createTestMaps,
  getTestMaps,
  getOneTestMaps,
  getSubjectLevelTestMaps,
} from "./Pandatestmap202003";

export {
  createStudentsAnsweringMaps,
  getStudentsAnsweringMaps,
} from "./Panda20200303studentsansweringmap";
export {
  completeSignup,
  completeSignupClicked,
} from "./completeSignUpActionTypes";
export { changeLanguage } from "./languages";
export { setShowMainNotification } from "./notifications";
export { setShowLeaderBoard } from "./student.leaderboard";

export {
  createAccount,
  getOneUsers,
  getUsers,
  updateUserAccountInfo,
  updateUserAccountInfoFinal,
  getOneUsersByOtp,
  login,
  loginwithtoken,
  socialLogin,
  resendotp,
  logout,
  passwordResetRequest,
  resetpassword,
  getRecoveryQuestions,
  passwordResetRequestWIthQuestions,
  getSchools,
  getDecodingToken,
  getDistrictSchools,
  createSchoolByStudent,
  getDecoding,
} from "./auth";

export {
  completeprofileinformation,
  getUserProfile,
  getCBCCombinations,
} from "./profileinformation";

export {
  getOGeniusModules,
  getExtraCurriculars,
  getLearningResources,
  getOpandaBooks,
  getModuleCourseStructures,
  getClassLevelTests,
  getClassLevelAndSubjectTests,
  getTests,
  getSubjectClassCourses,
  getModuleClassCourses,
  getClassCourses,
  getSubjectTests,
  getOneClassCourses,
  getLevelClassCourses,
  getLevelCourseStructures,
  getSubjectNames,
  getClassCoursePages,
  getPaymentOptions,
  getPaymentMethods,
} from "./previewpanda";

export {
  getHomeCarousel,
  getHomeWhoIsOpandaFor,
  getHomeHowItWork,
  getHomePartners,
  getHomeImpacts,
  getHomeValuePropositions,
  getHomeTestimonies,
} from "./home";

// --- exporting features actions
export { getFeatures } from "../actions/features/features";

export {
  settingsClicked,
  settingsStart,
  completeSettings,
} from "./settingsActionTypes";

export {
  paymentClicked,
  paymentStart,
  completePayment,
} from "./paymentActionTypes";

export {
  getClassesActiveTab,
  getTeacherSubjectNames,
  createSummaryNotes,
} from "./features/teacher/classes";
export { getPrograms } from "./common/programs";
export { makePayment, getExtraPackages } from "./payment";

export {
  getTeacherTestMaps,
  createTeacherTestMaps,
  initiateTestCreation,
  getOneTeacherTestMaps,
} from "./features/tests";

export { getTeacherPrograms } from "./features/teacher/programs";

export {
  getAutomatedTestListStart,
} from "./features/test.progress";

export { getAutomatedTestResultStart } from "./features/test.progress.result";