import React, { useEffect, useState } from "react";
import { PieChart, Pie, Tooltip } from "recharts";
import { BiRectangle } from "react-icons/bi";
import axios from "../../../../../axios-base";
import { useSelector } from "react-redux";




// CardTitle Component
const CardTitle = ({ name, color, alignment }) => (
  <h2
    className={`text-${color}-600 text-lg font-bold text-${alignment}`}
  >
    {name}
  </h2>
);

// CardBody Component
const CardBody = ({ name, color }) => (
  <h3
    className={`text-${color}-500 text-md font-semibold`}
  >
    {name}
  </h3>
);

// CardSubText Component
const CardSubText = ({ name }) => (
  <p className="text-gray-700 text-sm">{name}</p>
);

// StatsComponent
const StatsComponent = () => {
  const activityData = [
    { name: "Tests", value: 400, color: "#8884d8", fill: "#8884d8" },
    { name: "Summary Notes", value: 300, color: "#82ca9d", fill: "#82ca9d" },
    { name: "Question of the day", value: 300, color: "#ffc658", fill: "#ffc658" },
  ];
  // set state variables to store the data from the API
  const [activityData_, setActivityData] = useState([]);
  const userAuth = useSelector((state) => state.auth);
  // load the data from the API at first render then every 30 mins, using axios 
  useEffect(() => {
    axios
      .put(`${process.env.REACT_APP_BACKEND_URL}/opanda/stats/findUsersActivitiesThisWeek`, {
        schoolID: userAuth.user.id
      },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userAuth.token}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        //setActivityData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    const interval = setInterval(() => {
      axios
        .put(`${process.env.REACT_APP_BACKEND_URL}/opanda/stats/findUsersActivitiesThisWeek`,
          { schoolID: userAuth.user.id },

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userAuth.token}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          //setActivityData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 1800000);
    return () => clearInterval(interval);
  }, []);




  return (
    <div className="grid grid-cols-2 gap-2 bg-white px-2 py-2">
      {/* Pie Chart Section */}
      <div>
        <CardTitle name="Activity report" color="blue" alignment="left" />
        <PieChart width={200} height={200}>
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={activityData}
            cx={45}
            cy={45}
            innerRadius={30}
            outerRadius={50}
            paddingAngle={5}
            label
          />
          <Tooltip />
        </PieChart>
      </div>

      {/* Activities Section */}
      <div className="space-y-2">
        <CardBody name="Top Activities" color="blue" />
        <div className="space-y-2">
          {activityData.map((activity, index) => (
            <div
              key={index}
              className="flex flex-row items-start space-x-2"
            >
              {/* Legend Rectangle */}
              <BiRectangle
                style={{
                  backgroundColor: activity.color,
                  height: "24px",
                  width: "24px",
                }}
              />
              {/* Legend Text */}
              <CardSubText name={`${activity.name}: ${activity.value}`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StatsComponent;
