import { updateObject } from '../../shared/utility';
import { LOAD_LEADERBOARD_USERS_REQUEST,
    LOAD_LEADERBOARD_USERS_SUCCESS,
    LOAD_LEADERBOARD_USERS_FAILURE, SET_SHOW_LEADERBOARD } from '../actions/student.leaderboard';


const initialReducer = {
    loading: false,
    showLeaderBoard: false,
    data: []
};

function student_get_leaderboard_init(state, action) {
    return updateObject(state, {
        loading: true,
    });
}
function student_get_leaderboard_success(state, action) {
    return updateObject(state, {
        data: action.payload,
        loading: false,
        message: "success"
    });
}
function student_get_leaderboard_fail(state, action) {
    return updateObject(state, {
        message: action.payload,
        loading: false,
    });
}

const setShowLeaderBoard = (state, action) => {
    return updateObject(state, {
        showLeaderBoard: action.status,
    });
};

const reducer = (state = initialReducer, action) => {
    switch (action.type) {
        case SET_SHOW_LEADERBOARD:
            return setShowLeaderBoard(state, action);
        case LOAD_LEADERBOARD_USERS_REQUEST:
            return student_get_leaderboard_init(state, action);
        case LOAD_LEADERBOARD_USERS_SUCCESS:
            return student_get_leaderboard_success(state, action);
        case LOAD_LEADERBOARD_USERS_FAILURE:
            return student_get_leaderboard_fail(state, action);
        default:
            return state;
    }
};

export default reducer;